<template>
  <div>
    <div class="container" align="left">
      <div class="d-inline-block mt-5">
        <h4 class="mb-4"><strong>제 1장(총칙)</strong></h4>
        <h5 class="mt-5 mb-3"><strong>제 1조 (목적)</strong></h5>
        <p>
          본 약관은 SK핀크스㈜(이하 “사업자”라 한다)와 사업자가 운영하는 핀크스 골프클럽(이하 “골프장”이라 한다)의 모든
          내장객(이하 “이용자”라 한다)의 골프장 및 부대시설 이용에 관한 사항을 규정함을 목적으로 한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제 2조 (준수의무)</strong></h5>
        <p>본 약관은 사업자와 골프장의 회원 또는 비회원인 이용자에게 적용된다.</p>
        <h5 class="mt-5 mb-3"><strong>제 3조 (계약의 성립)</strong></h5>
        <p>
          골프장 이용계약은 이용자가 골프장이용을 청약하는 의사표시와 서명을 하고 예약자확인 절차를 마친 때에 성립한다.
          예약을 하지 않은 경우에는 입장절차를 마친 때 성립한다.
        </p>
        <h4 class="mt-5 mb-4"><strong>제 2장(일반 준수사항)</strong></h4>
        <h5 class="mt-5 mb-3"><strong>제 4조 (예약 및 위약규정)</strong></h5>
        <p class="numText0">
          1. 골프장을 이용하고자 하는 자는 이용예정일 [ 1 ]일 전까지 소정의 절차에 의하여 예약일, 경기시간, 예약자,
          동반자 등 필요한 사항을 예약하고 사업자의 승인을 받아야 한다.
        </p>

        <p class="numText0">
          2. 이용자가 예약을 불이행하는 경우에는 사업자는 예약 관련 위약 규정에 따라 일정기간의 예약 정지나 이용 제한
          조치를 취할 수 있다.<br />
        </p>

        <p class="numText0">3. ‘회사 홈페이지상 예약취소/변경 및 위약 규정 안내’에 따른다.</p>
        <h5 class="mt-5 mb-3"><strong>제 5조 (이용요금)</strong></h5>
        <p class="numText0">1. 골프장 이용요금은 다음 내역의 합계금액으로 한다.<br /></p>

        <p class="numText2">1) 그린피 : 골프코스 이용에 따른 요금<br /></p>
        <p class="numText2">2) 카트피 : 골프카트 이용 요금<br /></p>
        <p class="numText2">
          3) 기본요금 : 골프장 이용 시 기본적으로 발생되는 비용으로서 락커, 샤워실 등 클럽하우스 시설 이용에 부과되는
          요금 <br />
        </p>

        <p class="numText2">
          4) 제세공과금 : 부가가치세와 회원제골프장의 경우에는 개별소비세와 농어촌특별세, 교육세, 체육진흥기금 등
          <br />
        </p>

        <p class="numText2">5) 특별요금 : 기타 사업자가 별도로 정한 요금<br /></p>
        <p class="numText0">
          2. 제1항 제3호의 기본요금은 코스 이용과 관계없이 클럽하우스 입장 시 부과되는 요금이다.<br />
        </p>

        <p class="numText0">
          3. 사업자는 제1항 각 호의 세부내역을 프론트 기타 이용자가 보기 쉬운 곳에 비치 또는 게시하여야 한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제 6조 (요금의 정산 및 징수)</strong></h5>
        <p class="numText0">
          1. 입장절차를 마친 이용자가 경기 전 임의로 이용계약을 취소한 경우에는 기본요금 및 그린피의 50%(18홀 기준 9홀
          이용요금)를 징수하고, 경기 개시 후 임의로 이용계약을 중단 또는 취소한 경우 그린피를 100%(18홀 기준 18홀
          이용요금)를 징수한다.<br />
        </p>

        <p class="numText0">
          2. 강설, 폭우, 안개 등 천재지변 또는 그 밖에 불가항력적인 사유로 입장에 관한 절차를 마친 이용자팀 전원이
          경기를 마치지 못하게 된 경우에는 다음과 같이 이용요금을 정산한다.<br />
        </p>

        <p class="numText2">1) 1번째 홀까지 경기를 마치지 못한 경우 : 이용요금 – 기본요금<br /></p>
        <p class="numText2">2) 2번째 홀 이후 : (이용요금 – 기본요금) * {1 – (기 이용한 홀수 / 전체 홀 수)}<br /></p>
        <h5 class="mt-5 mb-3"><strong>제 7조 (이용시간 및 휴장)</strong></h5>
        <p class="numText0">
          1. 골프장의 개장시간은 계절의 변화 또는 일조 및 일몰 시간을 고려하여 사업자가 별도로 정한다.<br />
        </p>

        <p class="numText0">
          2. 골프장은 사정에 따라 정기 또는 임시로 휴장할 수 있으며 임시 휴장의 경우 2일 이상의 기간 전에 인터넷
          홈페이지에 게시하고 예약한 이용자에게 개별 통지하여야 한다. 다만 천재지변 또는 기상변화에 의한 휴장의 경우에는
          그러하지 아니한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제 8조 (이용제한 및 거절)</strong></h5>
        <p>사업자는 다음과 같은 경우에는 골프장 이용을 제한 또는 거절할 수 있다.</p>
        <p class="numText0">1. 예약시간 내에 경기 진행이 될 수 없을 때</p>
        <p class="numText0">2. 이용자가 본 약관을 위반하였을 때</p>
        <p class="numText0">3. 도박을 하거나, 진행을 지연시켜 타인의 골프장 이용에 큰 방해가 될 때</p>
        <p class="numText0">4. 천재지변, 기타 사유로 골프장을 폐장 또는 휴장할 때</p>
        <p class="numText0">5. 경기 능력이 현저히 부족하여 타인의 경기에 큰 방해가 될 때</p>
        <p class="numText0">
          6. 사업자의 임직원, 경기보조원, 다른 이용자 등에게 비신사적인 행위(성희롱, 폭력, 모욕 등)를 하였을 때
        </p>
        <p class="numText0">7. 골프장의 시설, 수목, 그린을 고의로 훼손하였을 때</p>
        <p class="numText0">8. 골프장 이용요금을 미납한 적이 있는 때</p>
        <p class="numText0">9. 타인의 신분을 도용하여 이용등록을 하거나 사업자의 신분확인 요구를 거절하였을 때</p>
        <p class="numText0">
          10. 대한골프협회규칙, 기타 이용자의 에티켓(복장, 음주 등)과 관련하여 사업자가 미리 고지한 준수사항을 지키지
          아니한 때
        </p>
        <p class="numText0">11. 사업자의 임직원 또는 경기보조원이 안전을 위하여 요청하는 조치를 준수하지 아니한 때</p>
        <p class="numText0">12. 기타 제반 여건상 경기자로서 자격이 없다고 명백히 판단될 때</p>
        <h5 class="mt-5 mb-3"><strong>제 9조 (경기규칙)</strong></h5>
        <p>
          모든 이용자는 “대한골프협회”와 사업자가 정한 경기규칙을 준수하여야 한다. 단 두 규칙 사이에 충돌이 있을 때에는
          사업자가 정한 경기규칙을 우선하여 적용한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제 10조 (추가이용)</strong></h5>
        <p>
          이용자는 1회 입장 시 18홀 경기를 원칙으로 하되, 사업자가 승인하는 경우 추가 경기가 가능하며 이 경우 추가요금을
          부담하여야 한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제 11조 (배상책임)</strong></h5>
        <p>
          이용자는 고의 또는 과실로 그린, 벙커, 건축물, 카트 등 골프장의 시설물이나 비품을 훼손하였을 때에는 그 손해액을
          배상하여야 한다.
        </p>
        <h4 class="mt-5 mb-4"><strong>제 3장(경기 시 준수사항)</strong></h4>
        <h5 class="mt-5 mb-3"><strong>제 12조 (안전관리의 원칙과 매너의 준수)</strong></h5>
        <p class="numText0">
          1. 이용자는 본 약관에 명시되어 있지 않거나 사업자의 임직원 또는 경기보조원의 조언이 없더라도 스스로 자기 또는
          타인의 안전에 위해가 될 수 있는 일체의 행위를 하여서는 아니 된다.<br />
        </p>

        <p class="numText0">
          2. 이용자는 신사적인 에티켓과 매너를 유지하고 질서를 지켜 타 이용자에게 지장을 주는 행위를 하여서는 아니
          된다.<br />
        </p>
        <p class="numText0">
          3. 이용자는 안전을 위하여 사업자의 임직원 또는 경기보조원이 요청한 사항을 준수하여야 한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제 13조 (준비 스윙 제한)</strong></h5>
        <p>이용자는 골프장의 잔디보호나 안전사고 방지, 기타 목적상 지정된 장소에서만 준비 스윙을 하여야 한다.</p>
        <h5 class="mt-5 mb-3"><strong>제 14조 (비거리 확인 의무)</strong></h5>
        <p>이용자는 경기보조원 또는 진행안내원 등의 조언이 있더라도 안전한 비거리를 스스로 확인하고 타구하여야 한다.</p>
        <h5 class="mt-5 mb-3"><strong>제 15조 (타구 주의 의무)</strong></h5>
        <p>이용자는 동반한 타자에 앞서 전방으로 진입해서는 아니 된다.</p>
        <h5 class="mt-5 mb-3"><strong>제 16조 (인접 홀에서의 타구)</strong></h5>
        <p>
          이용자는 인접 홀에 타구되지 않도록 충분히 주의하여 타구하여야 하며, 인접 홀에 타구 되었을 때에는 그 홀의
          경기자에 우선 양해하고 방해되지 아니하도록 타구하여야 한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제17조 (대피의무)</strong></h5>
        <p>
          이용자는 경기진행 중 민방위훈련이 실시되거나 사용자가 낙뢰 등 안전사고를 예방하기 위해 대피를 요청할 때에는
          이에 따라야 한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제18조 (복구의무)</strong></h5>
        <p>
          경기자는 페어웨이, 그린, 벙커 등 경기 중 발생시킨 훼손분을 복구하여야 하며, 각종 시설물을 변경시킨 경우에는
          원상 회복 시켜야 한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제19조 (경기 보조원 등 안내협조)</strong></h5>
        <p class="numText0">
          1. 이용자는 경기보조원, 진행안내원 등의 안내에 적극 협조하여야 하며, 안전 및 전체 이용자 공동의 편의를 위해
          인터넷 홈페이지와 골프장 시설물에 공고된 사항을 준수하여야 한다.<br />
        </p>

        <p class="numText0">2. 스코어카드는 이용자가 직접 기록하는 것을 원칙으로 한다.</p>
        <h5 class="mt-5 mb-3"><strong>제 20조 (일몰 시간 후의 경기금지)</strong></h5>
        <p>이용자는 경기진행 중 일몰이나 사업자가 정한 이용시간이 경과하면 경기를 도중에 종료하여야 한다.</p>
        <h5 class="mt-5 mb-3"><strong>제 21조 (골프카트 이용시 안전사고 방지)</strong></h5>
        <p>이용자는 골프카트 사용시 다음 각 사항을 준수하여야 한다.</p>
        <p class="numText0">
          1. 골프카트는 경기보조원만이 운전할 수 있으며, 이용자는 어떠한 사유로도 골프카트를 운전해서는 아니된다.<br />
        </p>
        <p class="numText0">2. 골프카트의 전면 또는 후면에 서 있으면 아니된다.<br /></p>
        <p class="numText0">3. 골프카트의 진행 중에는 반드시 좌석에 앉아야 한다.<br /></p>
        <p class="numText0">
          4. 반드시 인접 홀에서 운행되는 골프카트의 진행이나 주∙정차를 확인한 후 볼을 스트로크 하여야 한다.
        </p>
        <p class="numText0">
          5. 이용자는 낙뢰가 예상 될 때에는 골프카트에서 내린 후 안전하게 경기보조원이 안내하는 장소로 대피하여야
          한다.<br />
        </p>
        <p class="numText0">
          6. 기타 사용자 또는 경기보조원 등이 안전을 위하여 필요한 행위나 조치를 요구한 때에는 이를 따라야 한다<br />
        </p>

        <h5 class="mt-5 mb-3"><strong>제 22조 (기타 안전사고 방지)</strong></h5>
        <p>
          이용자는 연못, 절벽 등 안전 관리상 위험한 장소에는 접근을 금하여야 하며, 위험 장소에 진입하여 발생한 사고에
          대해 “사업자”는 사업자, 사업자의 이행보조자 및 피고용자의 귀책 사유가 없는 한 책임을 지지 아니한다.
        </p>
        <h4 class="mt-5 mb-4"><strong>제 4장(경기이외의 준수)</strong></h4>
        <h5 class="mt-5 mb-3"><strong>제 23조 (불조심 의무)</strong></h5>
        <p class="numText0">1. 이용자는 골프장 내에서 화재의 위험의 높은 물품을 휴대 해서는 아니 된다.<br /></p>

        <p class="numText0">2. 이용자는 지정된 장소 이외에서는 흡연을 할 수 없다.<br /></p>
        <p class="numText0">3. 이용자는 담배불 등을 주의하여 불조심을 항상 이행하여야 한다.</p>
        <h5 class="mt-5 mb-3"><strong>제 24조 (귀중품의 별도보관)</strong></h5>
        <p class="numText0">
          1. 이용자는 필요한 경우 귀중품 등 각종 물품에 대하여 그 품명과 가액을 기재하여 사업자의 확인 하에 사업자에게
          보관시켜야 한다. <br />
        </p>
        <p class="numText0">
          2. 제1항의 규정에 따라 사업자에게 보관시키지 아니한 경우에는 사업자는 그 물품의 분실, 훼손에 대하여 귀책사유가
          없는 한 책임을 지지 아니한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제 25조 (휴대품의 자기책임)</strong></h5>
        <p>
          이용자의 휴대품은 이용자 스스로 관리하는 것을 원칙으로 하며, “사업자”는 사업자, 사업자의 이행보조자 및
          피고용자의 귀책 사유가 없는 한 책임을 지지 아니한다
        </p>
        <h5 class="mt-5 mb-3"><strong>제 26조 (락카의 잠금관리)</strong></h5>
        <p>이용자는 락카실의 열쇠 또는 비밀번호를 본인이 책임지고 관리하며, 분실 시에는 제작 실비를 본인이 부담한다.</p>
        <h5 class="mt-5 mb-3"><strong>제 27조 (골프채의 인수)</strong></h5>
        <p>
          경기 종료 후 이용자가 골프채를 인수할 때에는 이용자는 골프채의 분실, 훼손의 여부를 확인하여야 하며, 사업자,
          이행보조자 및 피고용인의 귀책사유가 없는 한 골프채를 인수한 후에는 이의 분실, 훼손을 이유로 사업자에게 책임을
          물을 수 없다.
        </p>
        <h4 class="mt-5 mb-4"><strong>제 5장(회사의 의무와 면책)</strong></h4>
        <h5 class="mt-5 mb-3"><strong>제 28조 (친절봉사)</strong></h5>
        <p>
          사업자는 친절하게 봉사하는 자세로 최대한 편의를 제공하고 코스와 부대시설을 이용함에 있어 사고예방에 만전을
          기하여야 한다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제 29조 (천재지변과 불가항력의 면책)</strong></h5>
        <p>천재지변 및 불가항력에 의하여 이용자가 피해를 입을 때에는 사업자는 책임지지 아니한다.</p>
        <h5 class="mt-5 mb-3"><strong>제 30조 (약관의 게시)</strong></h5>
        <p class="numText0">
          1. 사업자는 본 약관을 이용자가 볼 수 있는 장소에 게시하여야 하며, 이용자가 요구하면 약관의 사본을 교부하여야
          한다.<br />
        </p>
        <p class="numText0">
          2. 사업자는 이 약관에 정하여져 있는 중요한 내용을 회원가입계약 또는 골프장 이용계약을 체결할 때 이용자가
          이해할 수 있도록 설명하여야 한다. 다만, 그 전에 설명을 들었던 이용자에게는 이 약관의 변경된 내용이 없으면 그의
          동의를 얻어 설명하지 아니할 수 있다.
        </p>
        <h5 class="mt-5 mb-3"><strong>제 31조 (기타)</strong></h5>
        <p class="mb-5">
          본 약관에 규정하지 않은 사항은 또는 약관의 해석상 다툼이 있는 사항에 대해서는 사업자와 이용자가 합의하여
          결정하되, 합의가 이루어지지 아니한 경우에는 관계법령 및 공정한 관행에 따른다.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TOSgolfclub',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
p {
  word-break: keep-all;
}
.numText0 {
  margin-left: 1.5rem;
  text-indent: -1.5rem;
}
.numText1 {
  margin-left: 1rem;
  text-indent: -1rem;
}
.numText2 {
  margin-left: 2.5rem;

  text-indent: -1rem;
}
.numText3 {
  margin-left: 3rem;
  text-indent: -0.5rem;
}
</style>
